import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';

export default function Contact({ location }) {
	return (
		<Layout location={location} lang="bg">
			<Seo title="Contact" />
			<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
				<div className="flex flex-col lg:items-center justify-between lg:flex-row">
					<div className="mb-10 lg:max-w-lg lg:pr-5 lg:mb-0">
						<div className="max-w-xl mb-6">
							<h2 className="max-w-lg mb-6 text-4xl tracking-tight text-gray-900 dark:text-white sm:text-5xl sm:leading-none">
								Свържете се с нас!
							</h2>
							<p className="text-base text-gray-700 dark:text-white md:text-lg">
								Имате нужда от проектиране на новия ви дом? Пишете ни.
							</p>
							<ul className="mt-8 md:grid md:grid-cols-1 gap-6 ml-0">
								<li className="mt-6 lg:mt-0">
									<div className="flex items-center">
										<span className="flex-shrink-0 flex items-center justify-center h-11 w-11 rounded-md bg-black text-white dark:text-black dark:bg-white">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="h-7 w-7"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
												<path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
											</svg>
										</span>
										<a href="mailto:atelier@atelier-motif.com ">
											<span className="ml-4 text-base leading-6 font-semibold text-gray-500 dark:text-white">
												atelier@atelier-motif.com
											</span>
										</a>
									</div>
								</li>
								<li className="mt-6 lg:mt-0">
									<div className="flex items-center">
										<span className="flex-shrink-0 flex items-center justify-center h-11 w-11 rounded-md bg-black text-white dark:text-black dark:bg-white">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="h-7 w-7"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
											</svg>
										</span>
										<a href="tel:+359886587186">
											<span className="ml-4 text-base leading-6 font-semibold text-gray-500 dark:text-white">
												+359 886 587 186
											</span>
										</a>
									</div>
								</li>
								<li className="mt-6 lg:mt-0">
									<div className="flex items-center">
										<span className="flex-shrink-0 flex items-center justify-center h-11 w-11 rounded-md bg-black text-white dark:text-black dark:bg-white">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="h-7 w-7"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path
													fillRule="evenodd"
													d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
													clipRule="evenodd"
												/>
											</svg>
										</span>
										<span className="ml-4 text-base leading-6 font-semibold text-gray-500 dark:text-white">
											Plovdiv, Bulgaria
										</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div className="relative lg:w-1/2">
						<StaticImage
							className="object-cover w-full shadow-lg"
							src="../images/two-chairs-with-painting.jpg"
							alt="two-chairs-with-painting.jpg"
						/>
					</div>
				</div>
			</div>
		</Layout>
	);
}
